<template>
  <div class="card card-custom card-stretch gutter-b">

    <div class="card-header border-0 py-5 ml-2">
      <h3 class="card-title align-items-start flex-column">
        <!-- <span class="card-label font-weight-bolder text-dark">Alla Mallar</span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">Nedanstående mallar används inom föreningen</span> -->
      </h3>
      <div class='d-flex justify-content-end'>

        <div class="card-toolbar">

          <a href="#" class="btn btn-primary font-weight-bolder font-size-sm" @click.prevent="createSMSClicked"
            ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>Nytt SMS</a
          >
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-header border-0 py-5 m-1">
      <div>
        <b-form-select v-model="perPage" :options="options"></b-form-select>
      </div>
      <b-pagination
        class="ml-auto mb-0"
        v-model="currentPage"
        :total-rows="smss.length"
        :per-page="perPage"
        aria-controls="sms-table"
        first-number
        last-number
      ></b-pagination>
    </div>

    <div class="card-body pt-0">
      <b-table
        id="sms-table"
        :fields="headers"
        :items="smss"
        :per-page="perPage"
        :current-page="currentPage"
        head-variant="light"
        class="mh-100"
        sticky-header
      >


        <template #cell(company_id)="row">
          <div class='justify-content-end d-flex'>

            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click="selectSMSClicked(row.item.sms_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>

          </div>
        </template>
      </b-table>
    </div>

      <!--end::Table-->
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>
<style lang="scss" scoped>

</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: "sms-table",
  props: ["smss"],
  emits: ['createSMSClicked', 'selectSMSClicked', 'deleteSMSClicked'],
  components: {
    
  },
  mixins: [ toasts ],
  watch: {

  },
  computed:{
    ...mapGetters([]),
  },
  mounted() {

  },
  methods: {
    createSMSClicked() {
      this.$emit('createSMSClicked');
    },
    selectSMSClicked(id) {
      this.$emit('selectSMSClicked', id);
    },
    deleteSMSClicked(id) {
      this.$emit('deleteSMSClicked', id);
    },
  },
  data() {
    return {
      showAll: false,
      currentPage: 1,
      perPage: 100,
      options: [
        100, 150, 200
      ],
      headers: [
        {
          key: 'sms_id',
          label: 'ID',
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'to_number',
          label: 'Till',
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'sent_at',
          label: 'Datum',
          thClass: 'w-165px align-middle',
          tdClass: 'w-165px align-middle',
          sortable: true
        },
        {
          key: 'company_id',
          label: 'Hantera',
          thClass: 'w-120px text-right pr-12 align-middle',
        },
      ]
    };
  }
};
</script>
